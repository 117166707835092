import { combineReducers } from '@reduxjs/toolkit';

import authSlice from '@/redux/auth/auth.slice.ts';
import breadcrumbsSlice from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import countriesSlice from '@/redux/countries/countries.slice.ts';
import devicesSlice from '@/redux/device/devices.slice.ts';
import didSlice from '@/redux/did/did.slice.ts';
import distributorsSlice from '@/redux/distributors/distributors.slice.ts';
import fruitSortSlice from '@/redux/fruitSort/fruitSort.slice.ts';
import fruitTypeSlice from '@/redux/fruitType/fruitType.slice.ts';
import organizationsSlice from '@/redux/organizations/organizations.slice.ts';
import parcelsSlice from '@/redux/parcels/parcels.slice.ts';
import statisticsSlice from '@/redux/statistics/statistics.slice.ts';
import usersSlice from '@/redux/users/users.slice.ts';

const reducer = combineReducers({
  auth: authSlice,
  user: usersSlice,
  distributor: distributorsSlice,
  fruitType: fruitTypeSlice,
  fruitSort: fruitSortSlice,
  parcel: parcelsSlice,
  device: devicesSlice,
  breadcrumbs: breadcrumbsSlice,
  organizations: organizationsSlice,
  dids: didSlice,
  statistics: statisticsSlice,
  countries: countriesSlice,
});

export default { reducer };
