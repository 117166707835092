import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSwitcher from '@/components/localization/LanguageSwitcher.tsx';

const LoginPageLeftSection: FC = () => {
  const { t } = useTranslation('translation');

  return (
    <div className="flex flex-col absolute justify-center items-start inset-0 text-white xl:text-5xl lg:text-4xl font-bold px-10">
      <h1 className="xl:text-5xl lg:text-4xl text-white font-bold">
        Smart Watering Distributor
      </h1>
      <p className="text-lg text-white font-light max-md:hidden mt-5">
        {t('welcome')}
      </p>
      <LanguageSwitcher />
    </div>
  );
};

export default LoginPageLeftSection;
