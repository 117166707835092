import { FC, useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import i18n from '@/i18n.ts';
import { changeLocaleForBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const LanguageSwitcherDropdown: FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('flag') || '🇷🇸'
  );
  const [selectedLanguageText, setSelectedLanguageText] = useState(
    localStorage.getItem('lng') || 'Serbian'
  );
  const dispatch = useAppDispatch();

  const handleLanguageChange = async (
    locale: string,
    flag: string,
    text: string
  ) => {
    setSelectedLanguageText(text);
    localStorage.setItem('lng', text);
    setSelectedLanguage(flag);
    localStorage.setItem('flag', flag);
    await i18n.changeLanguage(locale);
    localStorage.setItem('locale', locale);
    dispatch(changeLocaleForBreadcrumbs());
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="outline-none dark:outline-none mr-5 h-full flex flex-row items-center gap-2">
        <p>{selectedLanguage} </p>
        <p>{selectedLanguageText}</p>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white">
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('sr', '🇷🇸', 'Serbian')}
        >
          🇷🇸 Serbian
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('en', '🇬🇧', 'English')}
        >
          🇬🇧 English
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('es', '🇪🇸', 'Spanish')}
        >
          🇪🇸 Spanish
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('de', '🇩🇪', 'German')}
        >
          🇩🇪 German
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('sk', '🇸🇰', 'Slovak')}
        >
          🇸🇰 Slovak
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('sq', '🇦🇱', 'Albanian')}
        >
          🇦🇱 Albanian
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSwitcherDropdown;
