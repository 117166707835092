import { FC } from 'react';

import i18n from '@/i18n.ts';
import { changeLocaleForBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const LanguageSwitcher: FC = () => {
  const dispatch = useAppDispatch();

  const changeLanguage = async (lng: string, flag: string, text: string) => {
    await i18n.changeLanguage(lng);
    localStorage.setItem('locale', lng);
    localStorage.setItem('flag', flag);
    localStorage.setItem('lng', text);
    dispatch(changeLocaleForBreadcrumbs());
  };

  return (
    <div className="flex flex-row gap-2 pb-20 max-md:pb-5 text-lg">
      <span
        className="cursor-pointer"
        onClick={() => changeLanguage('sr', '🇷🇸', 'Serbian')}
      >
        <span>🇷🇸</span>
      </span>
      <span
        className="cursor-pointer"
        onClick={() => changeLanguage('en', '🇬🇧', 'English')}
      >
        <span>🇬🇧</span>
      </span>
      <span
        className="cursor-pointer"
        onClick={() => changeLanguage('es', '🇪🇸', 'Spanish')}
      >
        <span>🇪🇸</span>
      </span>
      <span
        className="cursor-pointer"
        onClick={() => changeLanguage('de', '🇩🇪', 'German')}
      >
        <span>🇩🇪</span>
      </span>
      <span
        className="cursor-pointer"
        onClick={() => changeLanguage('sk', '🇸🇰', 'Slovak')}
      >
        <span>🇸🇰</span>
      </span>
      <span
        className="cursor-pointer"
        onClick={() => changeLanguage('sq', '🇦🇱', 'Albanian')}
      >
        <span>🇦🇱</span>
      </span>
    </div>
  );
};

export default LanguageSwitcher;
