import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { config } from '@/config/config.ts';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getDevicesWithLocations } from '@/redux/device/devices.actions.ts';
import {
  DeviceLocations,
  DeviceLocationsMap,
  GetDevicesLocationResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const containerStyle = {
  width: '100%',
  height: '100%',
};

type DeviceMarkerInfoProps = {
  deviceToShow: DeviceLocationsMap;
  setHoveredDevice: (
    value:
      | ((prevState: DeviceLocationsMap) => DeviceLocationsMap)
      | DeviceLocationsMap
  ) => void;
};

const DeviceMarkerInfo: FC<DeviceMarkerInfoProps> = ({
  deviceToShow,
  setHoveredDevice,
}) => {
  return (
    <InfoWindow
      position={{
        lat: deviceToShow.lat,
        lng: deviceToShow.lng,
      }}
      onCloseClick={() => setHoveredDevice(null)}
    >
      <div className="min-w-[100px] min-h-[100px] px-2 py-3">
        <p className="text-sm font-bold">Ime uredjaja: {deviceToShow.name}</p>
        <p className="text-sm font-bold">
          Ime parcele: {deviceToShow.parcelName}
        </p>
        <p className="text-sm font-bold">
          Ime vlasnika: {deviceToShow.organisationName}
        </p>
      </div>
    </InfoWindow>
  );
};

const MapContent: FC = () => {
  const token = config.googleMapsToken;
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [devices, setDevices] = useState<DeviceLocationsMap[]>([]);
  const [hoveredDevice, setHoveredDevice] = useState<DeviceLocationsMap>(null);
  const [debouncedDevice, setDebouncedDevice] =
    useState<DeviceLocationsMap>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const adjustZoomAndCenter = () => {
    if (mapRef.current) {
      const bounds = new google.maps.LatLngBounds();
      devices.forEach((device) => {
        bounds.extend({ lat: device.lat, lng: device.lng });
      });
      mapRef.current.fitBounds(bounds);
    }
  };

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    adjustZoomAndCenter();
  };

  const handleErrorResponse = (response: GetDevicesLocationResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const getMapCenter = (devicesMap: DeviceLocationsMap[]) => {
    const totalLat = devicesMap.reduce((sum, device) => sum + device.lat, 0);
    const totalLng = devicesMap.reduce((sum, device) => sum + device.lng, 0);
    return {
      lat: totalLat / devicesMap.length,
      lng: totalLng / devicesMap.length,
    };
  };

  const groupDevicesByCoordinates = (deviceLocations: DeviceLocations[]) => {
    return deviceLocations.reduce(
      (groups, device) => {
        if (!groups[device.coordinates]) {
          // eslint-disable-next-line no-param-reassign
          groups[device.coordinates] = [];
        }
        groups[device.coordinates].push(device);
        return groups;
      },
      {} as Record<string, DeviceLocations[]>
    );
  };

  const setDataToShow = (deviceLocations: DeviceLocations[]) => {
    const devicesByCoordinates = groupDevicesByCoordinates(deviceLocations);
    const coordinates = Object.keys(devicesByCoordinates);
    const transformedDevices: DeviceLocationsMap[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const coordinate of coordinates) {
      const devicesForMap: DeviceLocationsMap[] = devicesByCoordinates[
        coordinate
      ].map((device, index) => {
        const deviceCoordinate = device.coordinates.split(',');
        return {
          ...device,
          lat: Number(deviceCoordinate[0]) + 0.002 * index,
          lng: Number(deviceCoordinate[1]) + 0.001 * index,
        };
      });

      transformedDevices.push(...devicesForMap);
    }

    const mapCenter = getMapCenter(transformedDevices);
    setDevices(transformedDevices);
    setCenter(mapCenter);
  };

  useEffect(() => {
    const fetchDevicesLocations = async () => {
      // @ts-ignore
      const response = await dispatch(
        getDevicesWithLocations(localStorage.getItem('id'))
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
        return;
      }

      setDataToShow(response.content);
    };

    fetchDevicesLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDevice(hoveredDevice);
    }, 200);
    return () => clearTimeout(timeout);
  }, [hoveredDevice]);

  useEffect(() => {
    adjustZoomAndCenter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  return (
    <LoadScript googleMapsApiKey={token}>
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        {devices.map((device) => (
          <Marker
            key={device.id}
            position={{ lat: device.lat, lng: device.lng }}
            onMouseOver={() => setHoveredDevice(device)}
            onMouseOut={() => setHoveredDevice(null)}
          />
        ))}
        {debouncedDevice && (
          <DeviceMarkerInfo
            deviceToShow={debouncedDevice}
            setHoveredDevice={setHoveredDevice}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContent;
