import { FC } from 'react';

import MapContent from '@/components/layout/maps/MapContent.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

const HomePageMapContent: FC = () => {
  return (
    <Card x-chunk="dashboard-users">
      <CardHeader>
        <CardTitle>Lokacija uredjaja</CardTitle>
      </CardHeader>
      <CardContent className="h-[500px]">
        <MapContent />
      </CardContent>
    </Card>
  );
};
export default HomePageMapContent;
