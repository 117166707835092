import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pie, PieChart } from 'recharts';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getUserOrganizationParcels,
  getUsersByDistributorId,
} from '@/redux/distributors/distributors.actions.ts';
import {
  GetDistributorUsersResponse,
  HumidityStatisticUser,
} from '@/redux/distributors/distributors.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getHumidityZonesByParcelId } from '@/redux/parcels/parcels.actions.ts';
import {
  GetHumidityZonesByParcelIdResponse,
  HumidityStatisticParcel,
  UserOrganizationParcelsResponse,
} from '@/redux/parcels/parcels.types.ts';
import {
  searchParcelsParcelStatisticFormData,
  searchUsersParcelStatisticFormData,
} from '@/zod/types.ts';

const chartConfig = {
  parcels: {
    label: 'Parcele',
  },
  dry: {
    label: 'Suvo',
    color: 'hsl(var(--chart-1))',
  },
  moderate: {
    label: 'Umereno',
    color: 'hsl(var(--chart-2))',
  },
  wet: {
    label: 'Vlažno',
    color: 'hsl(var(--chart-3))',
  },
  saturated: {
    label: 'Zasićeno',
    color: 'hsl(var(--chart-4))',
  },
} satisfies ChartConfig;

type HumidityData = {
  name: string;
  value: number;
  fill: string;
};

const HumidityStatusStatistic: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const userForm = useForm({
    resolver: zodResolver(searchUsersParcelStatisticFormData),
    defaultValues: {
      userName: '',
    },
  });

  const parcelForm = useForm({
    resolver: zodResolver(searchParcelsParcelStatisticFormData),
    defaultValues: {
      parcelName: '',
    },
  });

  const loggedDistributorId = localStorage.getItem('id');

  const [selectedUser, setSelectedUser] = useState<HumidityStatisticUser>();
  const [selectedParcel, setSelectedParcel] =
    useState<HumidityStatisticParcel>();
  const [users, setUsers] = useState<HumidityStatisticUser[]>();
  const [userNames, setUserNames] = useState<string[]>([]);
  const usersComboboxContent: ComboboxItemType[] = userNames.map((user) => {
    return {
      value: user,
      label: user,
    };
  });
  const [parcels, setParcels] = useState<HumidityStatisticParcel[]>();
  const [distributedParcels, setDistributedParcels] =
    useState<HumidityData[]>();
  const [parcelNames, setParcelNames] = useState<string[]>([]);
  const parcelsComboboxContent: ComboboxItemType[] = parcelNames.map(
    (parcel) => {
      return {
        value: parcel,
        label: parcel,
      };
    }
  );

  const handleErrorResponse = (
    response: GetDistributorUsersResponse | UserOrganizationParcelsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDisplayParcelHumidityClick = () => {
    if (!selectedParcel) {
      return;
    }
    router(
      `/parcel-zones-humidity-statistic/${selectedUser.organizationId}/${selectedParcel.id}`
    );
  };

  const setHumidityDiagramValues = (zones) => {
    const dry = zones.filter((e) => e.humidityStatus === 'dry');
    const moderate = zones.filter((e) => e.humidityStatus === 'moderate');
    const wet = zones.filter((e) => e.humidityStatus === 'wet');
    const saturated = zones.filter((e) => e.humidityStatus === 'saturated');

    const distributed = [
      { name: 'dry', value: dry.length, fill: '#d9534f' },
      { name: 'moderate', value: moderate.length, fill: '#f0ad4e' },
      { name: 'wet', value: wet.length, fill: '#5cb85c' },
      { name: 'saturated', value: saturated.length, fill: '#1a3e5f' },
    ];

    setDistributedParcels(distributed);
  };

  const handleHumidityErrorResponse = (
    response: GetHumidityZonesByParcelIdResponse
  ): void => {
    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (!loggedDistributorId) {
        return;
      }

      const usersResponse = await dispatch(
        getUsersByDistributorId({ distributorId: loggedDistributorId })
      ).unwrap();

      if (!usersResponse.success) {
        handleErrorResponse(usersResponse);
        return;
      }

      setUsers(usersResponse.content);
      setUserNames(
        usersResponse.content.map((user) =>
          !user
            ? 'Nepoznati korisnik'
            : `${user.name} ${user.surname} - ${user.organization}`
        )
      );
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchParcels = async () => {
      if (!selectedUser) {
        return;
      }

      const parcelsResponse = await dispatch(
        getUserOrganizationParcels({
          organizationId: selectedUser.organizationId,
        })
      ).unwrap();

      if (!parcelsResponse.success) {
        handleErrorResponse(parcelsResponse);
        return;
      }

      setParcels(parcelsResponse.content);
      setParcelNames(
        parcelsResponse.content.map((parcel) =>
          !parcel ? 'Unknown parcel' : parcel.name
        )
      );
    };

    fetchParcels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    const distributeParcelZones = async () => {
      if (!selectedParcel) {
        setDistributedParcels([]);
        return;
      }

      // @ts-ignore
      const zonesResponse: GetHumidityZonesByParcelIdResponse = await dispatch(
        getHumidityZonesByParcelId({
          organizationId: selectedUser.organizationId,
          parcelId: selectedParcel.id,
        })
      ).unwrap();

      if (!zonesResponse.success) {
        handleHumidityErrorResponse(zonesResponse);
        return;
      }

      setHumidityDiagramValues(zonesResponse.content);
    };

    distributeParcelZones();
  }, [dispatch, selectedParcel, selectedUser]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Statistika vlažnosti zemljišta</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>
                  {!selectedParcel
                    ? 'Raspoređenost zona odabrane parcele po statusima vlage'
                    : `Raspoređenost zona parcele ${selectedParcel.name} po statusima vlage`}
                </CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col justify-center">
                <div className="w-full flex items-center">
                  <Form
                    reset={userForm.reset}
                    formState={userForm.formState}
                    clearErrors={userForm.clearErrors}
                    control={userForm.control}
                    getFieldState={userForm.getFieldState}
                    getValues={userForm.getValues}
                    handleSubmit={userForm.handleSubmit}
                    register={userForm.register}
                    resetField={userForm.resetField}
                    setError={userForm.setError}
                    setFocus={userForm.setFocus}
                    setValue={userForm.setValue}
                    trigger={userForm.trigger}
                    unregister={userForm.unregister}
                    watch={userForm.watch}
                  >
                    <form className="w-full flex flex-col items-center">
                      <CustomFormCombobox
                        customForm={userForm}
                        name="userName"
                        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                        buttonStyle="w-full px-3 py-2 justify-between h-[40px] text-l text-l break-words whitespace-normal text-left"
                        items={usersComboboxContent}
                        placeholder="Odaberite korisnika"
                        onChange={() => {
                          setSelectedUser(
                            users.find(
                              (u) =>
                                `${u.name} ${u.surname} - ${u.organization}` ===
                                userForm.getValues().userName
                            )
                          );
                          setSelectedParcel(null);
                          parcelForm.reset();
                        }}
                      />
                    </form>
                  </Form>
                </div>
                <div className="w-full flex items-center">
                  <Form
                    reset={parcelForm.reset}
                    formState={parcelForm.formState}
                    clearErrors={parcelForm.clearErrors}
                    control={parcelForm.control}
                    getFieldState={parcelForm.getFieldState}
                    getValues={parcelForm.getValues}
                    handleSubmit={parcelForm.handleSubmit}
                    register={parcelForm.register}
                    resetField={parcelForm.resetField}
                    setError={parcelForm.setError}
                    setFocus={parcelForm.setFocus}
                    setValue={parcelForm.setValue}
                    trigger={parcelForm.trigger}
                    unregister={parcelForm.unregister}
                    watch={parcelForm.watch}
                  >
                    <form className="w-full flex flex-col items-center">
                      <CustomFormCombobox
                        customForm={parcelForm}
                        name="parcelName"
                        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                        buttonStyle="w-full px-3 py-2 justify-between h-[40px] text-l break-words whitespace-normal text-left"
                        items={parcelsComboboxContent}
                        placeholder="Odaberite parcelu iz korisnikove organizacije"
                        onChange={() => {
                          setSelectedParcel(
                            parcels.find(
                              (p) =>
                                p.name === parcelForm.getValues().parcelName
                            )
                          );
                        }}
                      />
                    </form>
                  </Form>
                </div>
                <ChartContainer config={chartConfig}>
                  <PieChart>
                    <Pie
                      data={distributedParcels}
                      dataKey="value"
                      nameKey="name"
                    />
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <ChartLegend
                      content={<ChartLegendContent nameKey="name" />}
                      layout="horizontal"
                      align="center"
                      verticalAlign="bottom"
                      wrapperStyle={{ paddingTop: 10 }}
                    />
                  </PieChart>
                </ChartContainer>
              </CardContent>
              <CardFooter>
                <Button onClick={onDisplayParcelHumidityClick}>
                  Prikaži sve zone odabrane parcele
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default HumidityStatusStatistic;
