import { FC, useState } from 'react';

import CreateFERTDeviceForm from '@/components/forms/CreateFERTDeviceForm.tsx';
import CreateSensDeviceForm from '@/components/forms/CreateSensDeviceForm.tsx';
import CreateStartDeviceForm from '@/components/forms/CreateStartDeviceForm.tsx';
import SelectTypeButton from '@/components/layout/devices/SelectTypeButton.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { deviceTypes } from '@/redux/device/devices.types.ts';

const CreateDeviceContent: FC = () => {
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>('');

  const onStartClick = () => {
    setSelectedDeviceType(deviceTypes.START);
  };

  const onSENSClick = () => {
    setSelectedDeviceType(deviceTypes.SENS);
  };

  const onFERTClick = () => {
    setSelectedDeviceType(deviceTypes.FERT);
  };
  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          <p className="max-sm:text-start text-center">Novi uredjaj</p>
          <SelectTypeButton
            onFERTClick={onFERTClick}
            onStartClick={onStartClick}
            onSENSClick={onSENSClick}
            selectedDeviceType={selectedDeviceType}
          />
        </CardTitle>
        <CardContent className="p-0">
          {selectedDeviceType === deviceTypes.FERT && <CreateFERTDeviceForm />}
          {selectedDeviceType === deviceTypes.START && (
            <CreateStartDeviceForm />
          )}
          {selectedDeviceType === deviceTypes.SENS && <CreateSensDeviceForm />}
        </CardContent>
      </CardHeader>
    </Card>
  );
};

export default CreateDeviceContent;
