import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { AddUserForDistributorFormData } from '@/components/forms/AddUserForDistributorForm.tsx';
import {
  setDistributors,
  setFilteredNumberOfDistributors,
  setFilteredNumberOfUsersForDistributors,
  setTotalNumberOfDistributors,
  setTotalNumberOfUsersForDistributors,
  setUsersForSelectedDistributor,
  updateTotalNumberOfUsersForDistributor,
} from '@/redux/distributors/distributors.slice.ts';
import {
  CreateDistributorResponse,
  GetDistributorsResponse,
  GetDistributorUsersResponse,
} from '@/redux/distributors/distributors.types.ts';
import { UserOrganizationParcelsResponse } from '@/redux/parcels/parcels.types.ts';
import {
  addDropdownUser,
  removeDropdownUser,
} from '@/redux/users/users.slice.ts';
import {
  GetUsersResponse,
  UpdateUserResponse,
} from '@/redux/users/users.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getFilteredDistributors = createAsyncThunk<
  GetDistributorsResponse,
  string
>('admin/get-distributors', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetDistributorsResponse>(
      `/admin/distributor${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setDistributors(axiosResponse.data.content.fetchedDistributors));
    dispatch(
      setTotalNumberOfDistributors(axiosResponse.data.content.totalCount)
    );
    dispatch(
      setFilteredNumberOfDistributors(axiosResponse.data.content.filteredCount)
    );
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getDistributorById = createAsyncThunk<CreateDistributorResponse, string>(
  'admin/get-distributor-by-id',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.get<CreateDistributorResponse>(
        `/admin/distributor/get-one?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const setDistributorForUser = createAsyncThunk<
  UpdateUserResponse,
  AddUserForDistributorFormData
>('admin/set-user-for-distributor', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.patch<UpdateUserResponse>(
      '/admin/distributor/update/set-user',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(removeDropdownUser(reqData.userId));
    dispatch(updateTotalNumberOfUsersForDistributor(1));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getFilteredUsersForDistributor = createAsyncThunk<
  GetUsersResponse,
  string
>('admin/get-users-for-distributor', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetUsersResponse>(
      `/admin/distributor/get-distributor-users${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(
      setUsersForSelectedDistributor(axiosResponse.data.content.fetchedUsers)
    );
    dispatch(
      setTotalNumberOfUsersForDistributors(
        axiosResponse.data.content.totalCount
      )
    );
    dispatch(
      setFilteredNumberOfUsersForDistributors(
        axiosResponse.data.content.filteredCount
      )
    );
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const removeDistributorForUser = createAsyncThunk<
  UpdateUserResponse,
  { userId: string }
>('admin/remove-distributor-for-user', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.patch<UpdateUserResponse>(
      '/admin/distributor/remove-distributor-for-user',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(addDropdownUser(axiosResponse.data.content));
    dispatch(updateTotalNumberOfUsersForDistributor(-1));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const deleteDistributor = createAsyncThunk<
  CreateDistributorResponse,
  { distributorId: string }
>('admin/delete-distributor', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.delete<CreateDistributorResponse>(
      `/admin/distributor/delete-distributor?distributorId=${reqData.distributorId}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getUsersByDistributorId = createAsyncThunk<
  GetDistributorUsersResponse,
  { distributorId: string }
>('distributor/get-users', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.get<GetDistributorUsersResponse>(
      `/distributor/user/get-users?distributorId=${reqData.distributorId}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getUserOrganizationParcels = createAsyncThunk<
  UserOrganizationParcelsResponse,
  { organizationId: string }
>('distributor/get-user-organization-parcels', async (reqData) => {
  try {
    const axiosResponse =
      await axiosServer.get<UserOrganizationParcelsResponse>(
        `/distributor/parcel/get-organization-parcels?organizationId=${reqData.organizationId}`
      );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  getFilteredDistributors,
  getDistributorById,
  setDistributorForUser,
  getFilteredUsersForDistributor,
  removeDistributorForUser,
  deleteDistributor,
  getUsersByDistributorId,
  getUserOrganizationParcels,
};
