import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import ZoneTableRow from '@/components/layout/zones/ZoneTableRow.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getHumidityZonesByParcelId } from '@/redux/parcels/parcels.actions.ts';
import {
  GetHumidityZonesByParcelIdResponse,
  Zone,
} from '@/redux/parcels/parcels.types.ts';

const zonesTableHeaders: TableHeaderType[] = [
  {
    id: 'zone',
    text: 'Zona',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'humidityStatus',
    text: 'Status vlage',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'humidityPercentage',
    text: 'Procenat vlažnosti',
    className: '',
    isSortDisabled: true,
  },
];

const ZonesTableSection: FC = () => {
  const router = useNavigate();
  const { parcelId, organizationId } = useParams();
  const dispatch = useAppDispatch();
  const [zones, setZones] = useState<Zone[]>([]);

  const onBackClick = () => {
    router(`/`);
  };

  const handleErrorResponse = (
    response: GetHumidityZonesByParcelIdResponse
  ): void => {
    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchZones = async () => {
      // @ts-ignore
      const zonesResponse: GetHumidityZonesByParcelIdResponse = await dispatch(
        getHumidityZonesByParcelId({ organizationId, parcelId })
      ).unwrap();

      if (!zonesResponse.success) {
        handleErrorResponse(zonesResponse);
        return;
      }

      setZones(zonesResponse.content);
    };

    fetchZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader>
        <CardTitle>Zone</CardTitle>
      </CardHeader>
      <CardContent className="max-sm:p-2">
        <Table>
          <CustomTableHeader
            tableName="zones"
            tableHeaders={zonesTableHeaders}
          />
          <TableBody>
            {zones.map((zone, index) => (
              <ZoneTableRow
                key={`zones-table-data-zone-${index + 1}`}
                zone={zone}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <Button
          className="bg-gray-600 text-white py-3 px-2 "
          onClick={onBackClick}
        >
          Nazad
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ZonesTableSection;
