import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import EditUserForm from '@/components/forms/EditUserForm.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import SelectUserParcelDialog from '@/components/layout/users/SelectUserParcelDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getOrganizations } from '@/redux/organizations/organizations.actions.ts';
import { GetOrganizationsResponse } from '@/redux/organizations/organizations.types.ts';
import { getParcelsForUser, getUserById } from '@/redux/users/users.actions.ts';
import {
  CreateUserResponse,
  GetParcelsForUserResponse,
  User,
} from '@/redux/users/users.types.ts';

type UserConnectionsButtonsProps = {
  onParcelButtonClick: () => void;
};

const UserConnectionsButtons: FC<UserConnectionsButtonsProps> = ({
  onParcelButtonClick,
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Button
        type="button"
        className="outline outline-[#2b90d9] text-[#2b90d9] px-2 py-2"
        onClick={onParcelButtonClick}
      >
        Parcela
      </Button>
    </div>
  );
};

const EditUserPage: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const router = useNavigate();
  const [parcelDialogOpened, setParcelDialogOpened] = useState(false);
  const [userForEdit, setUserForEdit] = useState<User>({
    organizationName: '',
    id: '',
    address: '',
    country: '',
    createdAt: '',
    createdBy: '',
    mbr: '',
    dateOfPayment: '',
    email: '',
    name: '',
    password: '',
    nameOfTheCompany: '',
    pib: '',
    phoneNumber: '',
    place: '',
    surname: '',
    differentBillingAddress: false,
    billingAddress: '',
    billingCountry: '',
    billingPlace: '',
    wayOfInforming: '',
    organizationId: '',
    zip: '',
    distributorId: '',
    language: '',
  });

  const handleErrorResponse = (
    response:
      | CreateUserResponse
      | GetParcelsForUserResponse
      | GetOrganizationsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onParcelButtonClick = async () => {
    // @ts-ignore
    const response = await dispatch(getParcelsForUser(userForEdit.id)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    if (response.content.length === 0) {
      toast.info('Korisnik nema ni jednu parcelu');
      return;
    }

    if (response.content.length === 1) {
      router(`/parcels/edit/${response.content[0].id}`);
      return;
    }

    setParcelDialogOpened(true);
  };

  useEffect(() => {
    const fetchUser = async () => {
      // @ts-ignore
      const response = await dispatch(getUserById(params.id)).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
        return;
      }

      setUserForEdit(response.content);
    };

    const fetchOrganizationsForCombobox = async () => {
      const response = await dispatch(
        getOrganizations(localStorage.getItem('id'))
      ).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchOrganizationsForCombobox();
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <Card x-chunk="edit-user-card">
            <CardHeader className="flex flex-row justify-between items-center">
              <CardTitle>Izmeni korisnika</CardTitle>
              <UserConnectionsButtons
                onParcelButtonClick={onParcelButtonClick}
              />
            </CardHeader>
            <CardContent>
              <EditUserForm existingUser={userForEdit} />
            </CardContent>
          </Card>
        </div>
      </div>
      <SelectUserParcelDialog
        isDialogOpen={parcelDialogOpened}
        setIsDialogOpen={setParcelDialogOpened}
      />
    </div>
  );
};

export default EditUserPage;
