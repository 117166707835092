import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LoginForm from '@/components/forms/LoginForm.tsx';
import LoginRightPageSection from '@/components/layout/login/LoginRightPageSection.tsx';

const LoginPageWelcomeSection: FC = () => {
  const { t } = useTranslation('translation');

  return (
    <div className="grid gap-2 text-center">
      <h1 className="text-3xl font-bold">{t('login')}</h1>
      <p className="text-balance text-muted-foreground">
        {t('enterCredentials')}
      </p>
    </div>
  );
};

const LoginPage: FC = () => {
  return (
    <div className="h-screen overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full w-full bg-[#343a40]">
        <div className="lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] rounded-lg overflow-hidden">
          <div className="flex items-center justify-center py-12 bg-white">
            <div className="mx-auto grid w-[350px] gap-6">
              <LoginPageWelcomeSection />
              <LoginForm />
            </div>
          </div>
          <div
            className="relative hidden bg-muted lg:block h-full bg-cover bg-center xl:w-[600px] lg:w-[500px]"
            style={{ backgroundImage: `url('/loginBg.png')` }}
          >
            <div className="flex flex-col absolute justify-center items-start inset-0 text-white font-bold">
              <LoginRightPageSection />
            </div>
          </div>
        </div>
      </div>
      <footer className="sticky bottom-0 bg-[#343a40] h-70px text-white text-center">
        <span>© 2024 Smart Watering</span>
      </footer>
    </div>
  );
};

export default LoginPage;
