import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import EditFERTDeviceForm, {
  EditFERTDeviceFormData,
} from '@/components/forms/EditFERTDeviceForm.tsx';
import EditSensDeviceForm, {
  EditSensDeviceFormData,
} from '@/components/forms/EditSensDeviceForm.tsx';
import EditStartDeviceForm, {
  EditStartDeviceFormData,
} from '@/components/forms/EditStartDeviceForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getFERTDeviceById,
  getSensDeviceById,
  getStartDeviceById,
} from '@/redux/device/devices.actions.ts';
import {
  deviceTypes,
  GetFERTDeviceResponse,
  GetSensDeviceResponse,
  GetStartDeviceResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import {
  transformFERTDeviceForForm,
  transformSensDeviceForForm,
  transformStartDeviceForForm,
} from '@/utils/device.util.ts';

const EditDeviceContent: FC = () => {
  const params = useParams();
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const [transformedStartDevice, setTransformedStartDevice] =
    useState<EditStartDeviceFormData>();
  const [transformedFERTDevice, setTransformedFERTDevice] =
    useState<EditFERTDeviceFormData>();
  const [transformedSensDevice, setTransformedSensDevice] =
    useState<EditSensDeviceFormData>();
  const [showParcelButton, setShowParcelButton] = useState(false);

  const handleErrorResponse = (
    response:
      | GetStartDeviceResponse
      | GetFERTDeviceResponse
      | GetSensDeviceResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onParcelClick = () => {
    router(`/parcels/edit/${params.parcelId}`);
  };

  const fetchFERTDevice = async () => {
    // @ts-ignore
    const response = await dispatch(
      getFERTDeviceById(params.deviceId)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }
    setTransformedFERTDevice(transformFERTDeviceForForm(response.content));
  };

  const fetchStartDevice = async () => {
    // @ts-ignore
    const response = await dispatch(
      getStartDeviceById(params.deviceId)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    setTransformedStartDevice(transformStartDeviceForForm(response.content));
  };

  const fetchSensDevice = async () => {
    // @ts-ignore
    const response = await dispatch(
      getSensDeviceById(params.deviceId)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }
    setTransformedSensDevice(transformSensDeviceForForm(response.content));
  };

  const fetchFunctionToCall: Record<string, () => void> = {
    START: fetchStartDevice,
    FERT: fetchFERTDevice,
    SENS: fetchSensDevice,
  };

  useEffect(() => {
    if (params.deviceType) {
      fetchFunctionToCall[params.deviceType]();
    }

    setShowParcelButton(params.showParcelButton === 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader className="flex flex-row justify-between items-center">
        <CardTitle>Izmeni uredjaj</CardTitle>
        <Button
          type="button"
          className="outline outline-[#2b90d9] text-[#2b90d9] px-2 py-2"
          onClick={onParcelClick}
          style={{ display: showParcelButton ? '' : 'none' }}
        >
          Parcela
        </Button>
      </CardHeader>
      <CardContent>
        {params.deviceType === deviceTypes.FERT && (
          <EditFERTDeviceForm transformedFERTDevice={transformedFERTDevice} />
        )}
        {params.deviceType === deviceTypes.START && (
          <EditStartDeviceForm
            transformedStartDevice={transformedStartDevice}
          />
        )}
        {params.deviceType === deviceTypes.SENS && (
          <EditSensDeviceForm transformedSensDevice={transformedSensDevice} />
        )}
      </CardContent>
    </Card>
  );
};

export default EditDeviceContent;
