import { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { routeToPage } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { BreadcrumbLinkType } from '@/redux/breadcrumbs/breadcrumbs.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type RoutesMiddlewareProps = {
  children: ReactNode;
};

const RoutesMiddleware: FC<RoutesMiddlewareProps> = ({ children }) => {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const dispatch = useAppDispatch();

  const generateBreadcrumb = (
    link: string,
    linkText: string,
    localeId: string
  ) => {
    const breadcrumbLink: BreadcrumbLinkType = {
      link,
      linkText,
      localeId,
      showSeparator: false,
    };
    dispatch(routeToPage(breadcrumbLink));
  };

  const routeConfig = {
    '/users': {
      entity: 'Korisnici',
      localeId: 'users',
      addContent: { text: 'Korisnika', localeId: 'users' },
    },
    '/parcels': {
      entity: 'Parcele',
      localeId: 'parcels',
      addContent: { text: 'Parcelu', localeId: 'parcels' },
    },
    '/devices': {
      entity: 'Uređaji',
      localeId: 'devices',
      addContent: { text: 'Uređaj', localeId: 'devices' },
    },
    '/fruit-types': { entity: 'Kulture', localeId: 'fruit-types' },
    '/delivery': { entity: 'Isporuka', localeId: 'delivery' },
    '/statistics': { entity: 'Statistika', localeId: 'statistics' },
    '/did': { entity: 'DID', localeId: 'did' },
    '/locations-devices': {
      entity: 'Lokacije uređaja',
      localeId: 'locations-devices',
    },
  };

  const handleRoutes = (path: string) => {
    const configKey = Object.keys(routeConfig).find((route) =>
      path.startsWith(route)
    );

    if (!configKey) {
      generateBreadcrumb('/', `${t('home')}`, 'home');
      return;
    }

    const { entity, localeId, addContent } = routeConfig[configKey];

    if (path.includes('/edit') && addContent) {
      generateBreadcrumb(
        path,
        `Izmeni ${addContent.text}`,
        addContent.localeId
      );
      return;
    }

    if (path.includes('/create') && addContent) {
      generateBreadcrumb(path, `Dodaj ${addContent.text}`, addContent.localeId);
      return;
    }

    generateBreadcrumb(path, entity, localeId);
  };

  useEffect(() => {
    handleRoutes(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return children;
};

export default RoutesMiddleware;
