import { FC, ReactNode, useEffect } from 'react';

import i18n from '@/i18n.ts';

type LocaleMiddlewareProps = {
  children: ReactNode;
};

const LocaleMiddleware: FC<LocaleMiddlewareProps> = ({ children }) => {
  const setLanguageFromLocalStorage = async () => {
    const locale = localStorage.getItem('locale');

    await i18n.changeLanguage(locale || 'sr');
  };

  useEffect(() => {
    setLanguageFromLocalStorage();
  }, []);

  return children;
};

export default LocaleMiddleware;
